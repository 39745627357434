<template>
  <div
    v-if="!!project && Object.values(project).length"
    class="feasibility-details-page"
  >
    <div class="breadcrumbs-wrapper">
      <p>
        <router-link to="/archive-projects">Archive Projects</router-link>
        <span>
          <span v-if="project.project_number">
            / # {{ project.project_number }}</span
          >
          <span v-if="project.feasibility_number">
            / # {{ project.feasibility_number }}</span
          >
        </span>
      </p>
    </div>

    <div class="project-wrapper">
      <div class="project-bg">
        <ProjectGeneralInfo :project="project" type="Archive Project" />

        <div
          v-if="!!project.lines && !!project.lines.length"
          class="lines-wrapper"
        >
          <div class="lines">
            <div
              v-for="line in project.lines"
              :key="line.id"
              :line="line"
              :project-id="project.id"
            >
              <p>
                <span class="green"
                  >Title: <b>{{ line.title }} </b></span
                >

                <span class="grey"> ( {{ line.cases_count }} cases)</span>
              </p>

              <p class="grey">Description: {{ line.description }}</p>

              <div v-if="!!line.proposal">
                <h4 v-if="!!line.proposal.user" class="grey">
                  Proposal made by:
                  <span class="green">{{ line.proposal.user.name }}</span>
                </h4>
                <!-- <p v-if="line.proposal.available" class="grey">
                  <b>Available: </b> {{ line.proposal.available }}
                </p> -->
                <p v-if="line.proposal.to_collect" class="grey">
                  <b>To collect: </b> {{ line.proposal.to_collect }}
                </p>
                <p v-if="line.proposal.ordered" class="grey">
                  <b>Ordered: </b> {{ line.proposal.ordered }}
                </p>
                <p v-if="line.proposal.comment" class="grey">
                  <b>Comment: </b> {{ line.proposal.comment }}
                </p>
                <!-- <p class="grey">{{ line.proposal }}</p> -->
              </div>

              <hr class="dashed" style="margin-bottom: 5px" />
            </div>
          </div>
        </div>

        <ProjectTextSection :project="project" />
      </div>
    </div>

    <div>
      <UserSidebar
        v-if="project.categories"
        :categories="project.categories"
        type="Archive Project"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ProjectGeneralInfo from "@/components/project/ProjectGeneralInfo.vue";
import ProjectTextSection from "@/components/project/ProjectTextSection.vue";
import UserSidebar from "@/components/base/UserSidebar.vue";

export default {
  components: {
    ProjectGeneralInfo,
    ProjectTextSection,
    UserSidebar,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    onMounted(async () => {
      await store.dispatch("projects/getProject", route.params.id);
    });

    const project = computed(() => store.getters["projects/project"]);

    return { project };
  },
};
</script>

<style lang="scss" scoped>
.feasibility-details-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.project {
  display: block;
  padding: 20px 30px;

  &-wrapper {
    flex-basis: 100%;
    min-height: 100%;
    padding-right: 20px;
  }

  &-bg {
    padding: 20px 30px;
    min-height: calc(100vh - 190px);
    background-color: var(--col-bg-secondary);
  }
}
</style>
